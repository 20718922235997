/**
 * This updates the DB to indicate whether breakout rooms are open or not.
 * We need this as we currently have to disable the app in breakout rooms.
 * @param {*} meetingUUID
 * @returns
 */
export default async function syncBreakoutRoomState(
  meetingUUID,
  zoomSdk,
  supabase
) {
  try {

    const breakoutRoomData = await zoomSdk.getBreakoutRoomList();
    const { error: updateError } = await supabase
      .from("meetings")
      .update({
        breakout_room_state: breakoutRoomData.state,
        updated_at: new Date().toISOString(),
      })
      .eq("meeting_id", meetingUUID);

    if (updateError) {
      console.error(updateError);
    }
    
    return breakoutRoomData.state;
  } catch (error) {
    // When no breakrooms exist an error is thrown
  }

  // Breakout rooms do not exist and therefore have no state.
  return null;
}
