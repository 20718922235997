import config from "../../plugins/config";

/**
 * Implements in-client oauth for Zoom App
 *
 * @see https://marketplace.zoom.us/docs/zoom-apps/guides/authentication/
 * @param {*} zoomSdk
 * @param {*} zoomSdkConfig
 */
export default async function auth(zoomSdk, zoomSdkConfig) {
  // @deprecated Leaving in for reference
  // const userContextStatus = zoomSdkConfig.auth.status;

  // if (!userContextStatus === "authorized") {
  //   try {
  //     const promptAuthorizeResult = await zoomSdk.promptAuthorize();
  //     console.log("[auth]", promptAuthorizeResult);
  //   } catch (error) {
  //     console.error("[auth]", error);
  //   }
  // }

  console.log("[auth]", "Attempting code challenge.");

  const codeChallengeRequest = await fetch(
    `${config.VITE_BACKEND_PUBLIC_URL}/zoomapp/in-client-authorize-params`,
    {
      method: "GET",
      credentials: "include",
    }
  ).then((r) => r.json());

  console.log("[auth]", "In client oAuth in progress.");

  zoomSdk.addEventListener("onAuthorized", handleOnAuthorizedEvent(zoomSdk));

  zoomSdk
    .authorize(codeChallengeRequest)
    .then((result) => {
      console.log("[auth]", "Authorize result", result);
    })
    .catch((error) => {
      console.error(error);
    });
}

function handleOnAuthorizedEvent(zoomSdk) {
  return async (event) => {
    console.log("[auth]", "In client onAuthorized event received.");

    const { code, state } = event;

    const userContext = await zoomSdk.getUserContext();
    const getMeetingUUIDResponse = await zoomSdk.getMeetingUUID();

    await fetch(`${config.VITE_BACKEND_PUBLIC_URL}/zoomapp/onauthorized`, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        code,
        state,
        href: window.location.href,
        userContext,
        meetingUUID: getMeetingUUIDResponse.meetingUUID,
      }),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    }).then(() => {
      console.log(
        "[auth]",
        "4. Backend returns succesfully after exchanging code for auth token.  Go ahead and update the UI"
      );
    });

    console.log("[auth]", "In client oAuth succeeded.");
  };
}
