<template>
  <div class="countdown-timer">
    <div v-if="!isStarted">
      <h4>Timer</h4>
      <div class="spacer--half"></div>
      <div class="countdown-input">
        <input
          id="minutes"
          type="number"
          v-model="inputMinutes"
          min="0"
          class="space-right--half"
        />
        <label for="minutes" class="space-right">min</label>
        <input
          id="seconds"
          type="number"
          v-model="inputSeconds"
          min="0"
          max="59"
          class="space-right--half"
        />
        <label for="seconds" class="space-right">sec</label>
        <button
          :disabled="inputMinutes === 0 && inputSeconds === 0"
          @click="startCountdown"
          class="button green"
          style="width: fit-content"
        >
          Start
        </button>
      </div>
      <div class="u-flex u-align-items--center">
        <p class="space-right">Play Sound When Timer Ends</p>
        <toggle-off-icon v-if="silent" @click="silent = false" />
        <toggle-on-icon v-else @click="silent = true" />
      </div>
    </div>
    <div v-if="isStarted" class="countdown-display">
      <button @click="cancelTimer" class="button red round">
        <x-icon />
      </button>
      <svg viewBox="0 0 100 100">
        <circle
          class="countdown-progress-bar"
          :class="{ finished: isFinished }"
          cx="50"
          cy="50"
          r="45"
        />
        <circle
          class="countdown-progress-fill"
          cx="50"
          cy="50"
          r="45"
          :stroke-dasharray="circumference"
          :stroke-dashoffset="progress"
        />
        <text class="countdown-progress-label" x="50" y="60">
          {{ minutes }}:{{ seconds < 10 ? "0" : "" }}{{ seconds }}
        </text>
      </svg>
      <button
        :disabled="isFinished"
        @click="toggleTimer"
        class="button round"
        :class="{ green: !isCounting, yellow: isCounting }"
      >
        <pause-icon v-if="isCounting" />
        <play-icon v-else />
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, onUnmounted } from "vue";
import PlayIcon from "../components/PlayIcon.vue";
import PauseIcon from "../components/PauseIcon.vue";
import ToggleOnIcon from "../components/ToggleOnIcon.vue";
import ToggleOffIcon from "../components/ToggleOffIcon.vue";
import XIcon from "../components/XIcon.vue";

const inputMinutes = ref(0);
const inputSeconds = ref(0);
const minutes = ref(0);
const seconds = ref(0);
const isCounting = ref(false);
const silent = ref(false);
const isStarted = ref(false);
const isFinished = ref(false);
const circumference = 2 * Math.PI * 45;
const progress = ref(circumference);

let countdownInterval;

function toggleTimer() {
  if (isCounting.value) {
    stopCountdown();
  } else {
    resumeCountdown();
  }
}

function startCountdown() {
  progress.value = 0;
  isStarted.value = true;
  isCounting.value = true;
  isFinished.value = false;
  const totalSeconds = inputMinutes.value * 60 + inputSeconds.value;
  if (totalSeconds > 0) {
    minutes.value = inputMinutes.value;
    seconds.value = inputSeconds.value;
    countdownInterval = setInterval(updateCountdown, 1000); // Update every second
  }
}

function stopCountdown() {
  clearInterval(countdownInterval);
  isCounting.value = false;
}

function resumeCountdown() {
  isCounting.value = true;
  countdownInterval = setInterval(updateCountdown, 1000); // Update every second
}

function cancelTimer() {
  isStarted.value = false;
  stopCountdown();
  minutes.value = 0;
  seconds.value = 0;
  inputMinutes.value = 0;
  inputSeconds.value = 0;
}

function updateCountdown() {
  const totalSeconds = minutes.value * 60 + seconds.value - 1;
  if (totalSeconds > -1) {
    const percentComplete =
      (totalSeconds * 100) / (inputMinutes.value * 60 + inputSeconds.value);
    progress.value = circumference * ((100 - percentComplete) / 100);
    if (seconds.value > 0) {
      seconds.value--;
    } else if (minutes.value > 0) {
      minutes.value--;
      seconds.value = 59;
    }
  } else {
    isFinished.value = true;
    progress.value = circumference;
    stopCountdown();
    if (!silent.value) {
      playSound();
    }
  }
}

function playSound() {
  var audio = new Audio("/timer.mp3");
  audio.play();
}

onUnmounted(() => {
  clearInterval(countdownInterval);
});
</script>

<style lang="scss" scoped>
.countdown-timer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 180px;
}

.countdown-timer .button {
  padding: 0 10px;
  text-transform: capitalize;
  height: 40px;
  line-height: 35px;
}
.countdown-timer .button.round {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  position: relative;
}

.countdown-input {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.countdown-input label {
  margin-right: 5px;
}

.countdown-input input {
  width: 50px;
}

.countdown-display svg {
  height: 150px;
}

.countdown-value {
  font-size: 36px;
  font-weight: bold;
}

.countdown-label {
  font-size: 14px;
  text-transform: uppercase;
}

.countdown-progress-bar {
  fill: none;
  stroke: var(--color-gray);
  stroke-width: 6;
  &.finished {
    stroke: var(--color-red);
  }
}

.countdown-progress-fill {
  fill: none;
  stroke: var(--color-tertiary);
  stroke-width: 6;
  stroke-linecap: round;
  transform-origin: center;
  transform: rotate(-90deg);
  transition: stroke-dashoffset 0.3s ease-in-out;
}

.countdown-progress-label {
  fill: var(--color-white);
  font-size: 24px;
  text-anchor: middle;
}

.x-icon,
.play-icon,
.pause-icon {
  position: absolute;
  width: 20px;
  top: -52px;
  left: 14px;
}
.play-icon {
  left: 15px;
}

.toggle-on-icon,
.toggle-off-icon {
  height: 30px;
  cursor: pointer;
}
</style>
