<template>
  <main class="equal-time-paywall">
    <p>
      You've reached your limit of 5 meetings this month on the free Equal Time
      plan. Please upgrade to get unlimited meetings!
    </p>
    <button class="button" @click="openPaymentPage">Upgrade Now</button>
    <p>Thank you for your support ♥</p>
  </main>
</template>

<script>
export default {
  methods: {
    openPaymentPage() {
      window.zoomSdk.openUrl({
        url: "https://equalicert.ck.page/products/equal-time-pro",
      });
    },
  },
};
</script>
<style scoped>
p {
  margin: 0 0 20px;
  font-size: 18px;
  line-height: 35px;
}
.button {
  background: var(--color-white);
  color: var(--color-black);
  margin-bottom: 20px;
  font-weight: 500;
}
.equal-time-paywall {
  background: var(--color-black);
  color: var(--color-white);
  text-align: center;
  padding: 2rem;
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 10;
}
</style>
