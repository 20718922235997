import fetchAndUpdateMeetingData from "./fetchAndUpdateMeetingData";
import mapMeetingParticipantToParticipantData from "./mapMeetingParticipantToParticipantData";
import { MEETING_DATA_POLL_INTERVAL_MS } from "./index";
import config from '../../plugins/config';

/**
 * Attendees listen to changes in the meeting state.
 * All attendees including host use this function.
 * @param {*} meetingUUID
 * @param {*} store
 * @returns
 */

export default async function initializeSubscriberNode(
  meetingUUID,
  store,
  supabase
) {

  console.log("[initializeSubscriberNode]",'Fetching intiial data...');
  await fetchAndUpdateMeetingData(meetingUUID, store, supabase);

  console.log("[initializeSubscriberNode]",'Initial data loaded.');

  console.log("[initializeSubscriberNode]",'Initializing realtime...');
  supabase
    .channel(`${config.VITE_BACKEND_PUBLIC_URL}:meeting:${meetingUUID}`)
    .on("broadcast", { event: "*" }, (event) => {
      const payload = event.payload;
      if (payload.table === "meeting_participants") {
        if (["UPDATE", "INSERT"].includes(payload.eventType)) {
          store.$patch(async (state) => {
            state.participants[payload.new.uuid] =
              mapMeetingParticipantToParticipantData(payload.new);
          });
        }
        return;
      }

      if (payload.table === "meetings") {
        if (["UPDATE", "INSERT"].includes(payload.eventType)) {
          store.$patch(async (state) => {
            state.meeting = payload.new;
          });
        }
        return;
      }
    })
    .subscribe((status) => {
      console.log("[initializeSubscriberNode] Realtime Status:", status);
    });

}
