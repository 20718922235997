import mapMeetingParticipantsToParticipantsData from "./mapMeetingParticipantsToParticipantsData";

export default async function fetchAndUpdateMeetingData(
  meetingUUID,
  store,
  supabase
) {


  // @todo add authentication
  const { data: meetingData, error } = await supabase
    .from("meetings")
    .select("*")
    .eq("meeting_id", meetingUUID)
    .single();

  if (error) {
    console.error(error);
    return;
  }

  const { data: meetingParticipantData, error: meetingParticipantError } =
    await supabase
      .from("meeting_participants")
      .select("*")
      .eq("meeting_id", meetingUUID);
      
  if (meetingParticipantError) {
    console.error(meetingParticipantError);
    return;
  }

  if (!meetingParticipantData) {
    console.error(
      "Meeting data not found. Meeting participants feature enabled."
    );
    return;
  }

  store.$patch(async (state) => {
    state.participants = mapMeetingParticipantsToParticipantsData(
      meetingParticipantData
    );
    state.meeting = meetingData;
    state.breakoutRoomState = meetingData.breakout_room_state;
    state.ownerAppNotActive = !meetingData.owner_app_active;
  });
}
