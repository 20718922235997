<template>
  <div class="transcripts-toast u-align-items--center">
    <h4>Transcripts</h4>
    <div class="spacer--quarter"></div>
    <svg
      width="1200pt"
      height="1200pt"
      version="1.1"
      viewBox="0 0 1200 1200"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="m425.45 837.27h-43.637v155.45c0 48 39.273 87.273 87.273 87.273h567.27c48 0 87.273-39.273 87.273-87.273v-785.45c0-48-39.273-87.273-87.273-87.273h-567.27c-48 0-87.273 39.273-87.273 87.273v155.45h43.637v-155.45c0-24.109 19.527-43.637 43.637-43.637h567.27c24.109 0 43.637 19.527 43.637 43.637v785.45c0 24.109-19.527 43.637-43.637 43.637h-567.27c-24.109 0-43.637-19.527-43.637-43.637z"
        />
        <path
          d="m469.09 294.55h567.27c12 0 21.816-9.8164 21.816-21.816s-9.8164-21.816-21.816-21.816l-567.27-0.003906c-12 0-21.816 9.8164-21.816 21.816s9.8164 21.82 21.816 21.82z"
        />
        <path
          d="m1058.2 381.82c0-12-9.8164-21.816-21.816-21.816h-338.18v43.637h338.18c11.996 0 21.816-9.8203 21.816-21.82z"
        />
        <path
          d="m1058.2 490.91c0-12-9.8164-21.816-21.816-21.816l-283.64-0.003906v43.637h283.64c12 0 21.82-9.8164 21.82-21.816z"
        />
        <path
          d="m1058.2 600c0-12-9.8164-21.816-21.816-21.816h-240v43.637h240c12-0.003906 21.82-9.8203 21.82-21.82z"
        />
        <path
          d="m1058.2 709.09c0-12-9.8164-21.816-21.816-21.816h-283.64v43.637h283.64c12 0 21.82-9.8203 21.82-21.82z"
        />
        <path
          d="m1058.2 818.18c0-12-9.8164-21.816-21.816-21.816l-338.18-0.003907v43.637h338.18c11.996 0 21.816-9.8164 21.816-21.816z"
        />
        <path
          d="m1058.2 927.27c0-12-9.8164-21.816-21.816-21.816l-567.28-0.003906c-12 0-21.816 9.8164-21.816 21.816s9.8164 21.816 21.816 21.816h567.27c12 0.003906 21.82-9.8125 21.82-21.812z"
        />
        <path d="m632.73 447.27h43.637v305.45h-43.637z" />
        <path d="m698.18 545.45h43.637v109.09h-43.637z" />
        <path d="m567.27 360h43.637v480h-43.637z" />
        <path d="m501.82 403.64h43.637v392.73h-43.637z" />
        <path d="m436.36 458.18h43.637v283.64h-43.637z" />
        <path d="m370.91 512.73h43.637v185.45h-43.637z" />
        <path d="m305.45 469.09h43.637v261.82h-43.637z" />
        <path d="m240 316.36h43.637v567.27h-43.637z" />
        <path d="m174.55 370.91h43.637v458.18h-43.637z" />
        <path d="m109.09 436.36h43.637v327.27h-43.637z" />
        <path d="m43.637 463.64h43.637v272.73h-43.637z" />
      </g>
    </svg>
    <div class="spacer--quarter"></div>
    <p>
      Login to our web app and connect your calendar to get full transcripts of
      your meetings to refer back to later.
    </p>
    <div class="spacer--half"></div>
    <button
      @click="
        openUrl(
          'https://app.equaltime.io/zoom?utm_source=zoom_app&utm_medium=zoom&utm_campaign=zoom&utm_content=transcripts_cta'
        )
      "
      class="button ghost"
    >
      Login Now
    </button>
  </div>
</template>

<script setup>
import TranscriptIcon from "../components/TranscriptIcon.vue";

async function openUrl(url) {
  window.zoomSdk.openUrl({ url: url });
}
</script>

<style lang="scss">
.transcripts-toast {
  svg {
    height: 80px;
    width: auto;
    path {
      fill: var(--color-white);
    }
  }
}
</style>
