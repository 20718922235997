import { defineStore } from "pinia";
import { createClient } from "@supabase/supabase-js";
import fetchAndUpdateMeetingData from '../plugins/zoomapp/fetchAndUpdateMeetingData';
import config from "../plugins/config";

export default defineStore({
  id: "equaltime",
  state: (): {
    activeSpeakerParticipantId: null;
    activeSpeakerStartTimestamp: null;
    timeline: [];
    meetingUUID: null;
    mode: null;
    meeting: {
      [key: string]: any
    } | null,
    participants: {
      [key: string]: { group: string; total: number; rating: number, excluded: boolean; };
    };
    totalDuration: number;
    ownerAppNotActive: boolean;
    isLoading: boolean;
    isBreakoutRoom: null;
    breakoutRoomState: null;
    userInfo: {
      id: string;
      product: string;
      recallAiAuthToken: string;
      created_at: Date;
      updated_at: Date;
    } | null;
    zoomUserContext: {
      status: "unauthenticated" | "authenticated" | "authorized";
      screenName: string;
      participantId: string;
      participantUUID: string;
      role: string;
    } | null;
  } => ({
    activeSpeakerParticipantId: null,
    activeSpeakerStartTimestamp: null,
    meetingUUID: null,
    participants: {},
    ownerAppNotActive: false,
    isLoading: true,
    timeline: [],
    mode: null,
    totalDuration: 0,
    isBreakoutRoom: null,
    breakoutRoomState: null,
    userInfo: null,
    zoomUserContext: null,
    meeting: null,
  }),
  getters: {},
  actions: {
    async resetMeeting() {
      if (!this.meetingUUID) {
        console.error("Meeting ID not set. Cannot update speaker group");
        return;
      }

      const VITE_SUPABASE_URL = config.VITE_SUPABASE_URL;
      const VITE_SUPABASE_ANON_KEY = config.VITE_SUPABASE_ANON_KEY;

      let supabase = createClient(
        <string>VITE_SUPABASE_URL,
        <string>VITE_SUPABASE_ANON_KEY,
        {
          global: {
            headers: { meeting_id: this.meetingUUID },
          }
        }
      );

      const { error } = await supabase
        .from("meeting_participants")
        .update({
          duration: 0,
          monologues: 0
        })
        .eq("meeting_id", this.meetingUUID);


      if (error) {
        console.error(error);
      }

      console.log('Meeting reset.');

    },
    async saveMeetingRating(rating: number) {
      if (!this.meetingUUID) {
        console.error("Meeting ID not set. Cannot update speaker group");
        return;
      }

      const participantUUID =
        this.zoomUserContext.participantUUID.toLowerCase();

      const VITE_SUPABASE_URL = config.VITE_SUPABASE_URL;
      const VITE_SUPABASE_ANON_KEY = config.VITE_SUPABASE_ANON_KEY;

      let supabase = createClient(
        <string>VITE_SUPABASE_URL,
        <string>VITE_SUPABASE_ANON_KEY,
        {
          global: {
            headers: { meeting_id: this.meetingUUID },
          }
        }
      );

      let uuid;
      for (const uuidKey in this.participants) {

        if (this.participants[uuidKey].participantUUID === participantUUID) {
          uuid = uuidKey
          break;
        }
      }

      if (!uuid) {
        throw new Error(`UUID not found for participant UUID ${participantUUID}`)
      }

      const { error: meetingParticipantError } = await supabase
        .from("meeting_participants")
        .update({ rating, updated_at: new Date().toISOString() })
        .eq("meeting_id", this.meetingUUID)
        .eq("zoom_participant_uuid", participantUUID);

      if (meetingParticipantError) {
        console.error(meetingParticipantError);
      }

    },
    async setParticipantGroup(uuid: string, group: string) {
      if (!this.meetingUUID) {
        console.error("Meeting ID not set. Cannot update speaker group");
        return;
      }

      const VITE_SUPABASE_URL = config.VITE_SUPABASE_URL;
      const VITE_SUPABASE_ANON_KEY = config.VITE_SUPABASE_ANON_KEY;


      let supabase = createClient(
        <string>VITE_SUPABASE_URL,
        <string>VITE_SUPABASE_ANON_KEY,
        {
          global: {
            headers: { meeting_id: this.meetingUUID },
          }
        }
      );

      const { error: meetingParticipantError } = await supabase
        .from("meeting_participants")
        .update({ group, updated_at: new Date().toISOString() })
        .eq("meeting_id", this.meetingUUID)
        .eq("uuid", uuid);

      if (meetingParticipantError) {
        console.error(meetingParticipantError);
      }

    },
    async setParticipantExcluded(uuid: string, excluded: boolean) {
      if (!this.meetingUUID) {
        console.error("Meeting ID not set. Cannot update speaker excluded");
        return;
      }

      const VITE_SUPABASE_URL = config.VITE_SUPABASE_URL;
      const VITE_SUPABASE_ANON_KEY = config.VITE_SUPABASE_ANON_KEY;


      let supabase = createClient(
        <string>VITE_SUPABASE_URL,
        <string>VITE_SUPABASE_ANON_KEY,
        {
          global: {
            headers: { meeting_id: this.meetingUUID },
          }
        }
      );

      const { error: meetingParticipantError } = await supabase
        .from("meeting_participants")
        .update({ excluded, updated_at: new Date().toISOString() })
        .eq("meeting_id", this.meetingUUID)
        .eq("uuid", uuid);

      if (meetingParticipantError) {
        console.error(meetingParticipantError);
      }

    },
    async setMeetingType(type: string) {
      if (!this.meetingUUID) {
        console.error("Meeting ID not set. Cannot update speaker group");
        return;
      }

      const VITE_SUPABASE_URL = config.VITE_SUPABASE_URL;
      const VITE_SUPABASE_ANON_KEY = config.VITE_SUPABASE_ANON_KEY;

      let supabase = createClient(
        <string>VITE_SUPABASE_URL,
        <string>VITE_SUPABASE_ANON_KEY,
        {
          global: {
            headers: { meeting_id: this.meetingUUID },
          }
        }
      );

      // @todo this may not be the best place for this update
      const { error } = await supabase
        .from("meetings")
        .update({
          type,
          updated_at: new Date().toISOString(),
        })
        .eq("meeting_id", this.meetingUUID);

      if (error) {
        console.error(error);
      }

    },
    // Set the meeting.invite_bot flag to true
    async inviteBot() {
      if (!this.meetingUUID) {
        console.error("Meeting ID not set. Cannot invite bot");
        return;
      }

      const VITE_SUPABASE_URL = config.VITE_SUPABASE_URL;
      const VITE_SUPABASE_ANON_KEY = config.VITE_SUPABASE_ANON_KEY;

      let supabase = createClient(
        <string>VITE_SUPABASE_URL,
        <string>VITE_SUPABASE_ANON_KEY,
        {
          global: {
            headers: { meeting_id: this.meetingUUID },
          }
        }
      );

      // @todo this may not be the best place for this update
      const { error } = await supabase
        .from("meetings")
        .update({
          invite_bot: true,
          updated_at: new Date().toISOString(),
          bot_only: true,
        })
        .eq("meeting_id", this.meetingUUID);

      if (error) {
        console.error(error);
      }

    },
    async saveActionItemContent(actionItemContent: string) {
      if (!this.meetingUUID) {
        console.error("Meeting ID not set. Cannot update speaker group");
        return;
      }

      const VITE_SUPABASE_URL = config.VITE_SUPABASE_URL;
      const VITE_SUPABASE_ANON_KEY = config.VITE_SUPABASE_ANON_KEY;

      let supabase = createClient(
        <string>VITE_SUPABASE_URL,
        <string>VITE_SUPABASE_ANON_KEY,
        {
          global: {
            headers: { meeting_id: this.meetingUUID },
          }
        }
      );

      // @todo this may not be the best place for this update
      const { error } = await supabase
        .from("meetings")
        .update({
          action_items: actionItemContent,
          updated_at: new Date().toISOString(),
        })
        .eq("meeting_id", this.meetingUUID);

      if (error) {
        console.error(error);
      }

    },
  },
});
