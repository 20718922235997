import config from "../config";
import eqdb from "../eqdb";

export default async function detectInterruption(
  meeting,
  event,
  participantsData,
  supabase
) {
  if (event.users.length > 1) {
    const interruptingEventUsers = event.users.filter(
      (user) =>
        user.participantUUID.toLowerCase() !==
        meeting.active_speaker_participant_id.toLowerCase()
    );

    for (const interruptingEventUser of interruptingEventUsers) {
      let meetingParticipant = await eqdb.findOneMeetingParticipant(
        {
          zoomParticipantUUID: interruptingEventUser.participantUUID,
          meetingUUID: meeting.meeting_id,
        },
        supabase
      );

      if (config.VITE_FEATURE_INTERRUPTIONS_NOTIFICATION) {
        zoomSdk.showNotification({
          type: "warn",
          title: "Interruption detected!",
          message: `${interruptingEventUsers
            .map((user) => user.screenName)
            .join(",")} is interrupting. They have ${
            meetingParticipant.interruptions + 1
          } interruptions total in this meeting.`,
        });
      }

      // Calculates duration of current speaking event.
      // We do this by looking at the timestamp of the last even
      // and taking the difference
      // Updates record in participants table with new speaking duration total
      await eqdb.updateMeetingParticipants(
        {
          meetingUUID: meeting.meeting_id,
          zoomParticipantUUID: interruptingEventUser.participantUUID,
        },
        {
          interruptions: meetingParticipant.interruptions + 1,
          updated_at: new Date().toISOString(),
        },
        supabase
      );
    }
  }

  return participantsData;
}
