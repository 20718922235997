import eqdb from "../eqdb";

// Sometimes a participant can exist but not be initialized.
export default async function syncParticipantData(
  eventUserParticipantUUID,
  eventUser,
  meetingUUID,
  supabase
) {
  let meetingParticipant = {};

  // A new participant may have joined the meeting since it started
  // so add their row data to the table.
  meetingParticipant = await eqdb.findOneMeetingParticipant( {
      zoomParticipantUUID: eventUserParticipantUUID,
      zoomParticipantId: eventUser.participantId,
      meetingUUID,
    },
    supabase
  );

  // Make sure existing record has initialized values
  const upsertRecord = {
    id: meetingParticipant.id,
    meeting_id: meetingUUID,
  };

  // Check to see if the meeting participant has participantUUID in
  // its record. If not update the record.
  // @hack This is a workaround for zoom webhooks not supplying the participant UUID.
  if ( !meetingParticipant.zoom_participant_uuid ) {
    upsertRecord.zoom_participant_uuid = eventUserParticipantUUID;
  }

  if ( !Number.isInteger( meetingParticipant.duration ) ) {
    upsertRecord.duration = 0;
  }

  if ( !Number.isInteger( meetingParticipant.monologues ) ) {
    upsertRecord.monologues = 0;
  }

  await eqdb.upsertMeetingParticipants( [ upsertRecord ], supabase );

  meetingParticipant = await eqdb.findOneMeetingParticipant( {
      zoomParticipantUUID: eventUserParticipantUUID,
      zoomParticipantId: eventUser.participantId,
      meetingUUID,
    },
    supabase
  );

  return {
    meetingParticipant,
  };
}
