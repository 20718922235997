import config from "../config";

// require meeting_participants table
async function upsertMeetingParticipants(data, supabase) {
  return await supabase.from("meeting_participants").upsert(...data, {
    returning: "minimal",
  });
}

async function updateMeetings({ meetingUUID }, data, supabase) {
  return await supabase
    .from("meetings")
    .update(data)
    .eq("meeting_id", meetingUUID);
}

// require meeting_participants table
async function updateMeetingParticipants(
  { meetingUUID, zoomParticipantUUID, zoomParticipantId },
  data,
  supabase
) {
  const orFilters = [];

  if (zoomParticipantUUID) {
    orFilters.push(
      `zoom_participant_uuid.eq.${zoomParticipantUUID.toLowerCase()}`
    );
  }

  if (zoomParticipantId) {
    orFilters.push(`zoom_participant_id.eq.${Number(zoomParticipantId)}`);
  }
  // Supabase doesn't like undefined so we hardcode -1 in that case to indicate it's not a value.
  return await supabase
    .from("meeting_participants")
    .update(data)
    .eq("meeting_id", meetingUUID)
    .or(orFilters.join(","));
}

// require meeting_participants table
async function findMeetingParticipants({ meetingUUID }, supabase) {
  return await supabase
    .from("meeting_participants")
    .select("*")
    .eq("meeting_id", meetingUUID);
}

async function findOneMeetingParticipant(
  { zoomParticipantId, zoomParticipantUUID, meetingUUID },
  supabase
) {
  const orFilters = [];

  if (zoomParticipantUUID) {
    orFilters.push(
      `zoom_participant_uuid.eq.${zoomParticipantUUID.toLowerCase()}`
    );
  }

  if (zoomParticipantId) {
    orFilters.push(`zoom_participant_id.eq.${Number(zoomParticipantId)}`);
  }

  const output = await supabase
    .from("meeting_participants")
    .select("*")
    .eq("meeting_id", meetingUUID)
    .or(orFilters.join(","));

  if (output.data.length > 1) {
    console.warn(
      "[findOneMeetingParticipant]",
      "Multiple entries found. returning first one."
    );
  }

  if (output.count === 0) {
    console.warn("[findOneMeetingParticipant]", "No entries found.");
    return null;
  }

  return output.data[0];
}

async function findOneMeeting({ meetingUUID }, supabase) {
  // last_active_speaker_event_timestamp is a replacement for active_speaker_start_timestamp
  return await supabase
    .from("meetings")
    .select(
      "*"
    )
    .eq("meeting_id", meetingUUID)
    .single();
}

export default {
  findOneMeeting,
  findMeetingParticipants,
  findOneMeetingParticipant,
  upsertMeetingParticipants,
  updateMeetingParticipants,
  updateMeetings,
};
