import eqdb from "../eqdb";
// @todo
export const MONOLOGUE_THRESHOLD_SECONDS = 120;

export default async function doMonologueCheck(
  eventUser,
  meetingParticipant,
  meeting,
  meetingUUID,
  supabase,
  eventUserParticipantUUID,
  zoomSdk
) {
  if (
    Number( eventUser.timestamp ) -
    meetingParticipant.active_speaker_start_timestamp >=
    MONOLOGUE_THRESHOLD_SECONDS
  ) {
    // Monologue detected but need to check if its already registered
    if ( !meeting.active_speaker_monologue_detected ) {
      const {
        data: meetingParticipants
      } = await eqdb.findMeetingParticipants( {
          meetingUUID
        },
        supabase
      );

      if ( meetingParticipants.length > 0 ) {
        let meetingParticipant = await eqdb.findOneMeetingParticipant( {
            zoomParticipantUUID: eventUserParticipantUUID,
            meetingUUID,
          },
          supabase
        );

        await eqdb.updateMeetingParticipants( {
            meetingUUID: meetingUUID,
            zoomParticipantUUID: eventUserParticipantUUID,
          }, {
            monologues: meetingParticipant.monologues + 1,
            updated_at: new Date().toISOString(),
          },
          supabase
        );

        await eqdb.updateMeetings( {
            meetingUUID
          }, {
            active_speaker_monologue_detected: true,
            updated_at: new Date().toISOString(),
          },
          supabase
        );

        zoomSdk.showNotification( {
          type: "warn",
          title: "Monologue detected!",
          message: `${
            eventUser.screenName
          } has been speaking for over ${Math.round(
            MONOLOGUE_THRESHOLD_SECONDS / 60
          )} minutes.`,
        } );
      } else {
        console.log(
          "[zoomapp]",
          "Only one participant in the meeting. Skipping monologue detection."
        );
      }
    }
  }
}
