import mapMeetingParticipantToParticipantData from "./mapMeetingParticipantToParticipantData";

export default function mapMeetingParticipantsToParticipantsData(
  meetingParticipants
) {
  const output = {};

  for (let meetingParticipant of meetingParticipants) {
    // We use the uuid field for supabase. Not the participantUUID from zoom
    // because sometimes the participantUUID gets regenerate but we merge them
    // in the db. The equal time uuid should always be for the same user.
    output[meetingParticipant.uuid] =
      mapMeetingParticipantToParticipantData(meetingParticipant);
  }

  return output;
}
