import zoomSdk from "@zoom/appssdk";
import useStore from "../../stores";
import {
  createClient
} from "@supabase/supabase-js";
import initializeHostNode from "./initializeHostNode";
import initializeSubscriberNode from "./initializeSubscriberNode";
import auth from "./auth";
import config from "../config";
import $router from "../../router";
import mapMeetingParticipantToParticipantData from "./mapMeetingParticipantToParticipantData";

console.log( "[zoomapp]", "init" );
// import axios from "axios";
let supabase;

// @todo We need check against env for builds because of the way
// vite does it's script builds.
// Adds a hostname check for production url
// Ideally this would be env agnostic.
const supabaseUrl = config.VITE_SUPABASE_URL;
const supabaseAnonKey = config.VITE_SUPABASE_ANON_KEY;

export const MEETING_DATA_POLL_INTERVAL_MS =
  config.VITE_MEETING_DATA_POLL_INTERVAL_MS;

// Necessary to work with sdk internals.
window.zoomSdk = zoomSdk;

// export function onMeetingConfigChanged(meetingUUID, zoomSdk, supabase) {
//   return (event) => syncBreakoutRoomState(meetingUUID, zoomSdk, supabase);
// }

export default {
  install: async ( app, options ) => {
    const store = useStore();

    let zoomSdkConfig;
    try {
      zoomSdkConfig = await zoomSdk.config( {
        capabilities: [
          "getUserContext",
          // "getMeetingJoinUrl",
          "getMeetingUUID",
          "getMeetingParticipants",
          "onActiveSpeakerChange",
          "showAppInvitationDialog",
          "sendAppInvitationToAllParticipants",
          "sendAppInvitationToMeetingOwner",
          "openUrl",
          "shareApp",
          "getBreakoutRoomList",
          "onMeetingConfigChanged",
          "showNotification",
          "onParticipantChange",
          // 'onMyActiveSpeakerChange'
          "authorize",
          "promptAuthorize",
          "onAuthorized",
        ],
      } );
    } catch ( error ) {
      console.log(
        "[zoomapp]",
        "Error initializing zoom sdk. Plugin disabled.",
        error
      );

      // For web only routes
      // These are currently intended to enable development only
      $router.afterEach( async ( to ) => {
        if ( to.name === "dashboard" ) {
          store.$patch( ( state ) => {
            const {
              product
            } = to.query;
            console.log( "[zoomapp]", "Loading Complete." );
            state.userInfo = {
              product: product ?? "equaltime",
            };
            state.isLoading = false;
          } );
          return;
        }
        // We only attempt to load meeting if on this specific route.
        if ( !( to.name === "meeting" && to.params.meetingId ) ) {
          return;
        }

        store.$patch( ( state ) => {
          console.log( "[zoomapp]", "Loading" );
          state.isLoading = true;
        } );

        const meetingId = to.params.meetingId;
        store.$patch( ( state ) => {
          state.meetingUUID = meetingId;
        } );

        const supabase = createClient( supabaseUrl, supabaseAnonKey, {
          global: {
            headers: {
              meeting_id: meetingId,
            },
          },
        } );

        // All attendees including host get initialized as subscriber nodes.
        await initializeSubscriberNode( meetingId, store, supabase );

        store.$patch( ( state ) => {
          console.log( "[zoomapp]", "Loading Complete." );
          state.isLoading = false;
        } );
      } );
      return;
    }

    if ( $router.currentRoute.value.name !== "meeting default" ) {
      console.warn( "[zoomapp] plugin skipped." );
      store.$patch( ( state ) => {
        state.isLoading = false;
      } );
      return;
    }

    console.log( "[zoomapp]", "zoomSdkConfig", zoomSdkConfig );
    const {
      runningContext
    } = zoomSdkConfig;

    store.$patch( ( state ) => {
      console.log( "[zoomapp]", "Loading" );
      state.isLoading = true;
    } );

    const userInfoRequest = await fetch(
      `${config.VITE_BACKEND_PUBLIC_URL}/zoomapp/user/me`, {
        credentials: "include",
      }
    );

    const userInfo = await userInfoRequest.json();

    store.$patch( ( state ) => {
      state.userInfo = userInfo;
    } );

    if ( runningContext === "inMainClient" ) {
      store.$patch( ( state ) => {
        state.isLoading = false;
      } );
      return;
    }

    console.log(
      "[zoomapp] in client oauth mode: ",
      config.VITE_FEATURE_IN_CLIENT_OAUTH
    );

    if ( config.VITE_FEATURE_IN_CLIENT_OAUTH ) {
      await auth( zoomSdk, zoomSdkConfig );
    }

    app.provide( "zoomSdk", zoomSdk );

    const userContext = await zoomSdk.getUserContext();

    store.$patch( ( state ) => {
      state.zoomUserContext = userContext;
    } );

    // We use the UUID as zoom recommends this over the numeric meeting ID which
    // is not guaranteed to be unique
    const {
      meetingUUID,
      parentUUID
    } = await zoomSdk.callZoomApi(
      "getMeetingUUID"
    );

    const isBreakoutRoom = parentUUID ? true : false;

    store.$patch( ( state ) => {
      state.meetingUUID = meetingUUID;
      state.parentUUID = parentUUID;
      state.isBreakoutRoom = isBreakoutRoom;
    } );

    // For backwards compatibility with breakout rooms
    if ( isBreakoutRoom ) {
      console.warn( "[zoomapp]", "In breakout room" );
      return;
    }

    supabase = createClient( supabaseUrl, supabaseAnonKey, {
      global: {
        headers: {
          meeting_id: meetingUUID,
        },
      },
    } );

    // If role is not host we get our meeting state from the server
    if ( !zoomSdkConfig.unsupportedApis.includes( "onActiveSpeakerChange" ) ) {
      console.log( "[zoomapp]", "Role is host. Initializing host node." );
      await initializeHostNode( meetingUUID, zoomSdk, supabase );
    }

    // All attendees including host get initialized as subscriber nodes.
    await initializeSubscriberNode( meetingUUID, store, supabase );

    store.$patch( ( state ) => {
      console.log( "[zoomapp]", "Loading Complete." );
      state.isLoading = false;
    } );
  },
};
