import { createRouter, createWebHashHistory } from "vue-router";
import MeetingView from "../views/MeetingView.vue";
import DashboardView from '../views/DashboardView.vue';
import NotFound from '../views/NotFound.vue';
import PayWall from '../views/PayWall.vue';

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: "/meeting/:meetingId",
      name: "meeting",
      component: MeetingView,
    },
    {
      path: "/",
      name: "meeting default",
      component: MeetingView,
    },
    {
      path: "/paywall",
      name: "pay wall",
      component: PayWall,
    },
    {
      path: "/dashboard",
      name: "dashboard",
      component: DashboardView,
    },
    { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound },
    // {
    //   path: "/about",
    //   name: "about",
    //   // route level code-splitting
    //   // this generates a separate chunk (About.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import("../views/AboutView.vue"),
    // },
  ],
});

export default router;
