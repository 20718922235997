<template>
  <div class="dashboard">
    <easy-spinner class="spinner" size="50" v-if="isLoading" />
    <div class="dashboard__content" v-else>
      <div class="u-align-items--center">
        <EqualicertLogo />
        <div class="spacer--half"></div>
        <h2>Zoom + Equal Time</h2>
        <div class="spacer"></div>
      </div>
      <div class="l-grid l-grid--large-gutters l-grid--2up l-grid--1up--medium">
        <div>
          <div class="image-with-overlay">
            <img
              alt="a woman using the equal time zoom app"
              src="/EqualTimeAppImage.webp"
            />
            <div class="overlay">
              <h4>Track Speaking Time</h4>
            </div>
          </div>
          <div class="spacer--half"></div>
          <div class="px-16">
            <p>
              Use the Equal Time Zoom App to get an instant, real-time view on
              who has spoken, for how long, and who you still need to hear from.
            </p>
            <div class="spacer--half"></div>
            <button
              @click="openDashboard('splash_page_complete_setup_cta')"
              class="button"
            >
              complete setup now
            </button>
          </div>
          <div class="spacer--half"></div>
        </div>
        <div>
          <div class="image-with-overlay">
            <img
              alt="a man and a woman using the equal time web app to view a meeting transcript"
              src="/MeetingTranscriptImage.jpg"
            />
            <div class="overlay">
              <h4>Get Meeting Transcripts</h4>
            </div>
          </div>
          <div class="spacer--half"></div>
          <div class="px-16">
            <p>
              Get automated meeting transcripts, action items, and summaries.
              Easily share transcripts with your team, search for keywords and
              questions asked, or edit transcripts.
            </p>
            <div class="spacer--half"></div>
            <button
              @click="openDashboard('splash_page_transcripts_cta')"
              class="button"
            >
              finish setup to get transcripts
            </button>
          </div>
          <div class="spacer--half"></div>
        </div>
        <div>
          <div class="image-with-overlay">
            <img
              alt="a woman using the equal time web app to view speaking times"
              src="/SpeakingTimeImage.webp"
            />
            <div class="overlay">
              <h4>Connect Your Calendars</h4>
            </div>
          </div>
          <div class="spacer--half"></div>
          <div class="px-16">
            <p>
              Track speaking time, get meeting transcripts, auto-summarization,
              topic detection, sentiment analysis and more AI features! Works
              for meeting hosts and participants.
            </p>
            <div class="spacer--half"></div>
            <button
              @click="openDashboard('splash_page_calendar_cta')"
              class="button"
            >
              connect calendars now
            </button>
          </div>
          <div class="spacer--half"></div>
        </div>
        <div>
          <div class="image-with-overlay">
            <img
              alt="a screenshot of the equal time zoom app showing how to find auto-open"
              src="/AutoOpenImage.jpg"
            />
            <div class="overlay">
              <h4>Enable Auto-Open</h4>
            </div>
          </div>
          <div class="spacer--half"></div>
          <div class="px-16">
            <p>
              Don't forget! Click on the "More Options" icon now (the three
              little dots) on the top right and select "Auto-open in Meetings"
              to have Equal Time open automatically.
            </p>
          </div>
          <div class="spacer--half"></div>
        </div>
      </div>
    </div>
    <div class="spacer--double"></div>
    <hr />
    <div class="spacer--double"></div>
    <h4 class="u-align-items--center">
      Using The Real-time Zoom App During Your Meetings (Hosts Only)
    </h4>
    <div class="spacer"></div>
    <img src="/HowTo1.png" alt="equal time how to - open the zoom app" />
    <div class="spacer"></div>
    <p>
      To use the Equal Time app in your Zoom meeting, click the "Apps" button in
      the bottom navigation bar. This will open up the Zoom Apps panel, where
      you will see Equal Time listed (along with any other apps you have
      installed).
    </p>
    <div class="spacer--half"></div>
    <p>
      Next, click on Equal Time logo
      <img src="/logo.png" width="40px" alt="equal time logo " />
      to load the app.
    </p>
    <div class="spacer--triple"></div>
    <div class="apexchart">
      <h6>Speaking Time</h6>
      <div class="spacer"></div>
      <div class="l-grid l-grid--2x3 l-grid--1up--medium">
        <img
          class="u-align-items--center"
          src="/SpeakingTime.png"
          alt="equal time how to - speaking time"
        />
        <div>
          <p>
            The Speaking Time bar chart shows the percentage of speaking time
            for each participant. The participants' bars change color in real
            time depending on the speaking balance of the meeting. Green is
            good!
          </p>
        </div>
      </div>
      <div class="spacer--double"></div>
      <div class="l-grid l-grid--2x3 l-grid--1up--medium">
        <img
          class="u-align-items--center"
          src="/SpeakingTime2.png"
          alt="equal time how to - speaking time group"
        />
        <div>
          <p>
            Click on the A, B or C tag to the right of a participant's name to
            group meeting participants. This is helpful if you want to track
            speaking time of groups in addition to individuals.
          </p>
        </div>
      </div>
    </div>
    <div class="spacer--triple"></div>
    <div class="apexchart">
      <h6>Inclusion Score</h6>
      <div class="spacer"></div>
      <div class="l-grid l-grid--2x3 l-grid--1up--medium">
        <img
          class="u-align-items--center"
          src="/InclusionScore.png"
          alt="equal time how to - inclusion score"
        />
        <div>
          <p>
            Your inclusion score is on a scale of 0 to 100, and is calculated
            based on the participation and monologue scores. This score will
            update in real time as the meeting progresses.
          </p>
          <div class="spacer"></div>
          <ul>
            <li>
              Your Participation Score reflects how well speaking times are
              balanced. If everyone is in the green, you get a perfect score. If
              everyone is in the red, you get no points.
            </li>
            <li>
              Your Monologue Score is calculated based on how many monologues
              you've had so far. If you have no monologues, you get a perfect
              score.
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="spacer--triple"></div>
    <div class="apexchart">
      <h6>Monologues</h6>
      <div class="spacer"></div>
      <div class="l-grid l-grid--2x3 l-grid--1up--medium">
        <img
          class="u-align-items--center"
          src="/Monologues.png"
          alt="equal time how to - meeting tag"
        />
        <div>
          <p>
            A monologue is registered when the active speaker talks for at least
            2 minutes without another speaker talking (only one monologue is
            registered per speaker per active speaker period). Click on the
            "Monologues" button to see more details about which speakers have
            had monologues.
          </p>
        </div>
      </div>
    </div>
    <div class="spacer--triple"></div>
    <div class="apexchart">
      <h6>Restarting Equal Time</h6>
      <div class="spacer"></div>
      <div class="l-grid l-grid--2x3 l-grid--1up--medium">
        <img
          class="u-align-items--center"
          src="/Restart.png"
          alt="equal time how to - restart meeting"
        />
        <div>
          <p>
            Click the "Restart" button to start a new meeting and end the
            current one. Doing this will zero out your meeting metrics. Use this
            feature if you have multiple meetings using the same meeting link.
          </p>
        </div>
      </div>
    </div>
    <div class="spacer--triple"></div>
    <div class="apexchart">
      <h6>Group Speaking Balance</h6>
      <div class="spacer"></div>
      <div class="l-grid l-grid--2x3 l-grid--1up--medium">
        <img
          class="u-align-items--center"
          src="/GroupSpeakingBalance.png"
          alt="equal time how to - restart meeting"
        />
        <div>
          <p>
            The Group Speaking Balance chart shows the percentage of speaking
            time for the groups you created above. If you hover over any slice
            of the pie chart, you can see the total speaking time for that
            group.
          </p>
        </div>
      </div>
    </div>
    <div class="spacer--triple"></div>
    <h3>Frequently Asked Questions</h3>
    <div class="spacer--half"></div>
    <p>
      If you need more help, please
      <a @click="openDashboard('splash_page_help')">
        click here to view our Zoom help center and Frequently Asked Questions.
      </a>
    </p>
    <div class="spacer--half"></div>
  </div>
</template>

<script lang="ts">
import EqualicertLogo from "../components/EqualicertLogo.vue";
import useStore from "../stores";
import { mapState } from "pinia";

export default {
  name: "DashboardView",
  methods: {
    showAppInvitationDialog: async function () {
      await window.zoomSdk.callZoomApi("showAppInvitationDialog");
    },
    openDashboard: async function (content) {
      window.zoomSdk.openUrl({
        url: `https://app.equaltime.io/zoom?utm_source=zoom_app&utm_campaign=zoom&utm_medium=zoom&utm_content=${content}`,
      });
    },
  },
  components: { EqualicertLogo },
  computed: {
    ...mapState(useStore, ["isLoading", "userInfo"]),
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/main.scss";
.dashboard {
  padding: var(--space-4) var(--space-8);
}
.spinner {
  position: fixed;
  z-index: 100;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.image-with-overlay {
  position: relative;
  img {
    height: 300px;
    width: 100%;
    object-fit: cover;
  }
  .overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba($color: #ffffff, $alpha: 0.8);
    padding: 16px;
    h4 {
      padding: 0;
      margin: 0;
    }
  }
}

.px-16 {
  padding: 0 16px;
}
</style>
