export default function mapMeetingParticipantToParticipantData(
  meetingParticipant
) {
  // We use the uuid field for supabase. Not the participantUUID from zoom
  // because sometimes the participantUUID gets regenerate but we merge them
  // in the db. The equal time uuid should always be for the same user.
  return {
    id: meetingParticipant.id,
    uuid: meetingParticipant.uuid,
    participantUUID: meetingParticipant.zoom_participant_uuid
      ? meetingParticipant.zoom_participant_uuid.toLowerCase()
      : null,
    participantId: meetingParticipant.zoom_participant_id,
    total: Number.isInteger(meetingParticipant.duration)
      ? meetingParticipant.duration
      : 0,
    monologues: Number.isInteger(meetingParticipant.monologues)
      ? meetingParticipant.monologues
      : 0,
    interruptions: meetingParticipant.interruptions,
    screenName: meetingParticipant.screen_name,
    group: meetingParticipant.group,
    excluded: meetingParticipant.excluded,
  };
}
