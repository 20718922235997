import eqdb from "../eqdb";

export default async function changeActiveSpeaker(
  eventUserParticipantUUID,
  meetingUUID,
  eventUser,
  supabase ) {

  await eqdb.updateMeetingParticipants( {
      meetingUUID: meetingUUID,
      zoomParticipantUUID: eventUserParticipantUUID,
      participantId: eventUser.participantId,
    }, {
      screen_name: eventUser.screenName,
      active_speaker_start_timestamp: Number( eventUser.timestamp ),
      updated_at: new Date().toISOString(),
    },
    supabase
  );

  // Resets start timestamp for this speaker. This is used to
  // detect monologues
  // @deprecated
  // participantsData[eventUserParticipantUUID].active_start_timestamp = Number(
  //   eventUser.timestamp
  // );
  // Resets the meeting fields that track active speakers info
  await eqdb.updateMeetings( {
      meetingUUID
    }, {
      owner_app_active: true,
      active_speaker_monologue_detected: false,
      active_speaker_participant_id: eventUserParticipantUUID,
      updated_at: new Date().toISOString(),
    },
    supabase
  );
}
