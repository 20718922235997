import onActiveSpeakerChange from "./onActiveSpeakerChange";
import syncBreakoutRoomState from "./syncBreakoutRoomState";
import config from "../../plugins/config";

/**
 * Host nodes listens to
 * @param {*} store
 * @param {*} meetingUUID
 */
export default async function initializeHostNode(
  meetingUUID,
  zoomSdk,
  supabase
) {

  const {
    participants
  } = await zoomSdk.callZoomApi( "getMeetingParticipants" );

  const upsertRecords = [];

  for ( const participant of participants ) {
    // Checks to see if row exists in meeting_participants table
    const {
      data: existingParticipant
    } = await supabase
      .from( "meeting_participants" )
      .select( "*" )
      .eq( "meeting_id", meetingUUID )
      .or(
        `zoom_participant_uuid.eq.${participant.participantUUID},zoom_participant_id.eq.${participant.participantId}`
      )
      .single();

    const upsertRecord = {
      meeting_id: meetingUUID,
      screen_name: participant.screenName,
      interruptions: config.VITE_FEATURE_INTERRUPTIONS ? 0 : null,
      monologues: 0,
      zoom_participant_id: participant.participantId,
      zoom_participant_uuid: participant.participantUUID,
      role: participant.role,
      updated_at: new Date().toISOString(),
    };

    // If there's an existing record id add it so it upserts instead
    // of creating a new record.
    if ( existingParticipant ) {
      upsertRecord.id = existingParticipant.id;
      upsertRecord.group = existingParticipant.group;

      // meeting_participants fields are initialized with NULL.
      // This sets it to 0 in the case where its the first time loading
      // with the app
      upsertRecord.duration = Number.isInteger( existingParticipant.duration ) ?
        existingParticipant.duration :
        0;
      upsertRecord.monologues = Number.isInteger( existingParticipant.monologues ) ?
        existingParticipant.monologues :
        0;

      if ( config.VITE_FEATURE_INTERRUPTIONS ) {
        upsertRecord.interruptions = Number.isInteger(
            existingParticipant.interruptions
          ) ?
          existingParticipant.interruptions :
          0;
      }

      // We only update a record if there's an existing participant.
      // Inserting new participants is handled via the webhook
      upsertRecords.push( upsertRecord );
    }

    if ( upsertRecords.length ) {
      await supabase.from( "meeting_participants" ).upsert( upsertRecords, {
        returning: "minimal",
      } );
    }
  }

  // Sets owner app active flag to "true" and meeting url
  await supabase
    .from( "meetings" )
    .update( {
      owner_app_active: true,
      owner_app_active_timestamp: new Date().toISOString(),
      updated_at: new Date().toISOString(),
    } )
    .eq( "meeting_id", meetingUUID );

  zoomSdk.addEventListener(
    "onActiveSpeakerChange",
    onActiveSpeakerChange( meetingUUID, zoomSdk, supabase )
  );

  zoomSdk.addEventListener( "onMeetingConfigChanged", () =>
    syncBreakoutRoomState( meetingUUID, zoomSdk, supabase )
  );

  // zoomSdk.addEventListener(
  //   "onParticipantChange",
  //   onParticipantChange(meetingUUID, supabase)
  // );
}
