import eqdb from "../eqdb";
import syncBreakoutRoomState from "./syncBreakoutRoomState";
import detectInterruption from "./detectInterruption";
import doMonologueCheck from "./doMonologueCheck";
import config from "../config";
import syncParticipantData from './syncParticipantData';
import accrueParticipantSpeakingDuration  from './accrueParticipantSpeakingDuration';
import changeActiveSpeaker  from './changeActiveSpeaker';

export default function onActiveSpeakerChange(meetingUUID, zoomSdk, supabase) {
  return async (event) => {
    console.log('event', event)
    const breakoutRoomState = await syncBreakoutRoomState(
      meetingUUID,
      zoomSdk,
      supabase
    );

    console.log('[onActiveSpeakerChange] breakoutRoomState', breakoutRoomState)

    if (breakoutRoomState === "open") {
      console.log(
        "[onActiveSpeakerChange]",
        "Breakout rooms open. Ignoring active speaker events."
      );
      return {
        status: "ok",
        message: "Breakout room open. Ignoring active speaker event.",
      };
    }

    const { data: meeting } = await eqdb.findOneMeeting(
      { meetingUUID },
      supabase
    );

    if (!meeting) {
      throw Error("No meeting detected!");
    }

    const currentActiveSpeakerEventUser = event.users.find(
      (user) =>
        user.participantUUID.toLowerCase() ===
        meeting.active_speaker_participant_id
    );

    const eventUser = currentActiveSpeakerEventUser ?? event.users[0];

    // Zoom returns participant ID in upper case but supabase converts this to lowercase,
    // therefore we lowercase all UUIDs to do comparisons.
    const eventUserParticipantUUID = eventUser.participantUUID.toLowerCase();

    let participantsData = {};

    // @deprecated
    let meetingParticipant;
    ({ meetingParticipant } = await syncParticipantData(
      eventUserParticipantUUID,
      eventUser,
      meetingUUID,
      supabase
    ));


    if (config.VITE_FEATURE_INTERRUPTIONS) {
      await detectInterruption(meeting, event, participantsData, supabase);
    }

    // When the active speaker changes reset the active speaker and timestamp.
    // Active speaker has changed.
    if (meeting.active_speaker_participant_id !== eventUserParticipantUUID) {
      await changeActiveSpeaker(
        eventUserParticipantUUID,
        meetingUUID,
        eventUser,
        supabase
      );
    } else {
      await doMonologueCheck(
        eventUser,
        meetingParticipant,
        meeting,
        meetingUUID,
        supabase,
        eventUserParticipantUUID,
        zoomSdk
      );
    }

    await accrueParticipantSpeakingDuration(
      eventUserParticipantUUID,
      meetingUUID,
      supabase,
      eventUser,
      meeting,
      participantsData
    );

    // @todo We may want to move to a time series in the future
    // state.timeline.push(event);

    return {
      status: "ok",
      message: "Active speaker event processed",
      event,
    };
  };
}


