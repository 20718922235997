<template>
  <apexchart type="pie" :options="options" :series="series"></apexchart>
</template>

<script type="text/javascript">
import useStore from "../stores";
import { mapState } from "pinia";

export default {
  props: {
    groupALabel: {
      type: String,
      default: "A",
    },
    groupBLabel: {
      type: String,
      default: "B",
    },
    groupCLabel: {
      type: String,
      default: "C",
    },
  },
  computed: {
    options() {
      return {
        labels: this.labels,
        dataLabels: {
          enabled: false,
        },
        legend: {
          position: "bottom",
        },
        colors: [
          "rgb(167, 226, 227)",
          "rgb(204, 204, 222)",
          "rgb(242, 204, 143)",
          "rgb(211, 211, 211)",
        ],

        states: {
          normal: {
            filter: {
              type: "none",
              value: 0,
            },
          },
          hover: {
            filter: {
              type: "none",
              value: 0,
            },
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: "none",
              value: 0,
            },
          },
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: (seriesName) => {
              const seconds = `${seriesName % 60}`;
              return `${Math.floor(seriesName / 60)}:${seconds.padStart(
                2,
                "0"
              )} minutes`;
            },
            title: {
              formatter: (seriesName) => `${seriesName} —`,
            },
          },
        },
      };
    },
    categories() {
      const categories = [];
      categories.push("A", "B", "C", "NA");
      return categories;
    },
    labels() {
      const labels = [];
      labels.push(
        this.groupALabel,
        this.groupBLabel,
        this.groupCLabel,
        "Ungrouped (tag speakers to use)"
      );
      return labels;
    },
    series() {
      const series = [0, 0, 0, 0];
      for (const participantUUID in this.participants) {
        console.log(this.participants[participantUUID].group);
        if (
          this.categories.includes(this.participants[participantUUID].group)
        ) {
          series[
            this.categories.indexOf(this.participants[participantUUID].group)
          ] += this.participants[participantUUID].total;
        } else {
          series[this.categories.indexOf("NA")] +=
            this.participants[participantUUID].total;
        }
      }

      // If there are no times yet fudge an n/a time of 1
      // so the empty chart will show.
      if (
        !series.filter((seriesTime) => {
          return seriesTime > 0;
        }).length
      ) {
        return [0, 0, 0, 1];
      }

      return series;
    },
    // gives access to this.counter inside the component
    // same as reading from store.counter
    ...mapState(useStore, [
      "activeSpeakerParticipantId",
      "participants",
      "timeline",
    ]),
  },
  data: function () {
    return {};
  },
};
</script>

<style>
.apexcharts-tooltip {
  color: #000e29 !important;
}
</style>
