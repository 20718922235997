import eqdb from "../eqdb";

export default async function accrueParticipantSpeakingDuration(
  eventUserParticipantUUID,
  meetingUUID,
  supabase,
  eventUser,
  meeting) {
  let meetingParticipant = await eqdb.findOneMeetingParticipant(
    {
      zoomParticipantUUID: eventUserParticipantUUID,
      meetingUUID,
    },
    supabase
  );

  // Calculates duration of current speaking event.
  // We do this by looking at the timestamp of the last even
  // and taking the difference
  // Updates record in participants table with new speaking duration total
  await eqdb.updateMeetingParticipants(
    {
      meetingUUID,
      zoomParticipantUUID: eventUserParticipantUUID,
      participantId: eventUser.participantId,
    },
    {
      duration: meetingParticipant.duration +
        Number(eventUser.timestamp) -
        (meeting.last_active_speaker_event_timestamp ??
          Number(eventUser.timestamp)),
      updated_at: new Date().toISOString(),
    },
    supabase
  );

  // Sets last_active_speaker_event_timestamp
  // for next event to compare against
  await eqdb.updateMeetings(
    { meetingUUID },
    {
      active_speaker_start_timestamp: Number(eventUser.timestamp),
      last_active_speaker_event_timestamp: Number(eventUser.timestamp),
      updated_at: new Date().toISOString(),
    },
    supabase
  );
}
