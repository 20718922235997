<template>
  <div class="donut-chart">
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 40 40"
      class="donut"
      :class="{ red: to < 30, yellow: to >= 30 && to <= 70 }"
    >
      <circle
        class="donut-ring"
        cx="20"
        cy="20"
        r="15.91549430918954"
        fill="transparent"
        stroke-width="3.5"
      ></circle>
      <circle
        class="donut-segment"
        cx="20"
        cy="20"
        r="15.91549430918954"
        fill="transparent"
        stroke-width="3.5"
        :stroke-dasharray="`${to} ${100 - to}`"
        stroke-dashoffset="25"
      ></circle>
      <g class="donut-text">
        <text y="57%" transform="translate(0, 2)">
          <tspan x="50%" text-anchor="middle" class="donut-percent">
            {{ to }}
          </tspan>
        </text>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "DonutChart",
  props: {
    to: {
      type: Number,
      default: null,
    },
  },
};
</script>

<style lang="scss">
.donut {
  width: 120px;
  margin: auto;
}

.donut-ring {
  stroke: var(--color-white);
}

.donut-segment {
  transform-origin: center;
  stroke: var(--color-green);
}

.donut.red .donut-segment {
  stroke: var(--color-red);
}

.donut.yellow .donut-segment {
  stroke: var(--color-quaternary-darker);
}

.donut-text {
  font-family: var(--font-family-secondary);
  fill: var(--color-black);
}

.donut-percent {
  font-size: 1em;
}
</style>
