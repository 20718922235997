import { createApp } from "vue";
import { createPinia } from "pinia";

import "./assets/main.scss";

import App from "./App.vue";
import router from "./router";
import VueApexCharts from "vue3-apexcharts";
import zoomAppPlugin from "./plugins/zoomapp";
import config from "./plugins/config";
import easySpinner from "vue-easy-spinner";

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faUserSecret, faFaceSmile, faFaceMeh, faFaceFrown, faXmark } from '@fortawesome/free-solid-svg-icons'

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";


/* add icons to the library */
library.add(faUserSecret)
library.add(faFaceSmile);
library.add(faFaceMeh);
library.add(faFaceFrown);
library.add(faXmark);



/* add font awesome icon component */

const app = createApp(App);

if (config.VITE_SENTRY_DSN) {
  Sentry.init({
    app,
    dsn: config.VITE_SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", config.VITE_WEBAPP_HOST, /^\//],
      }),
    ],
    environment: config.VITE_ENVIRONMENT,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

// @deprecated
if (window.appConfigGlobalProperties) {
  app.config.globalProperties = {
    ...app.config.globalProperties,
    ...config
  }
}

app.component('font-awesome-icon', FontAwesomeIcon)

app.use(easySpinner, {
  prefix: "easy",
});

app.use(createPinia());
app.use(router);
app.use(zoomAppPlugin);
app.use(VueApexCharts);


app.mount("#app");
